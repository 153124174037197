<template>
<div>
    <div slot="content">
      <v-container fluid >
        <template>
          <h1>Shipping Results</h1>
            <iframe
            id="2ship"
            title="Ship"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowfullscreen="true"
            allow="geolocation; microphone; camera"
            src="https://ship2.2ship.com/Pharmaguide/ShippingList/ShippingList/ShippingList"
            frameborder="0"
            style="width: 1px;
            min-width: 100%;
            min-height:100vh;
            border:none;"
            scrolling="yes"
            ></iframe>
        </template>
        </v-container>
    </div>
</div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {},
  computed: {},
  methods: {},
}
</script>
<style scoped>
h3 {
  /* font-weight: 400; */
  font-size: 16px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}
#noPadding {
  padding: 0;
}
.fixedElement {
  /* position: fixed; */
  padding: 1% 2%;
  width: 100%;
  z-index: 20;
}
</style>

<script>
export default {
  data() {
    return {}
  },
}
</script>

